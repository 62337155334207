<template>
	<navBar/>
	<div class="main">
        <div class="header">
            <div class="profile-gradient" >
                <!-- <div class="top border-radius-bottom" :class="backgroundStyle">
                </div> -->
                <img v-if="this.$route.query.user_id" class="top border-radius-bottom" :src="`${this.image_api}/${this.profile_data.cover}`"/>
                <img v-if="!this.$route.query.user_id " class="top border-radius-bottom" :src="`${this.image_api}/${this.profile.cover}`"/>

            </div>
            <div class="bottom profile-bottom" v-if="this.$route.query.user_id">
                <div class="profile-container">
                    <img v-if="!this.profile_data.image" 
                        height="170" width="170"
                        class="profile-pic"
                        src="@/assets/images/profiles/default.png" >
                    <img
                        v-if="this.profile_data.image"
                        :src="`${image_api}/${this.profile_data.image}`"
                        height="170" width="170"
                        class="profile-pic"
                    />
                </div>
                <div>
                    <div class="pt-3" style="margin-left: 20px;">
                        <h5 class="title fw-bolder mb-0 text-capitalize">{{profile_data.first_name +" "+ profile_data.last_name}}</h5>
                        <div class="members">
                            <span v-if="this.profile_data.role == 'seller'" class="fs-5">Ratings: ({{ratings}})</span><br>
                            <strong v-if="this.profile_data.role == 'seller'">{{ solds }} sold</strong>
                        </div>
                        <div class="members-profile mt-0 justify-content-between">
                            <div>
                                <!-- <img :src="getImagePath(i)" v-for="i in 10" :key="i" /> -->
                                <!-- <h6>{{profile_data.contact_number}}</h6>
                                <h6>{{profile_data.email_address}}</h6> -->
                            </div>
                            <div >
                                <button style="background-color: #0866FF;" class="border-0 p-2 rounded-2 mx-1 text-white" @click="messageUser()">
                                    <i class="bx bx-send px-1 fw-bold fs-6"></i>
                                    Message
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom profile-bottom" v-if="!this.$route.query.user_id">
                <div class="profile-container">
                    <img v-if="!this.profile.image" 
                        height="170" width="170"
                        class="profile-pic"
                        src="@/assets/images/profiles/default.png" >
                    <img
                        v-if="this.profile.image"
                        :src="`${image_api}/${this.profile.image}`"
                        height="170" width="170"
                        class="profile-pic"
                    />
                </div>
                <div>
                    <div class="pt-3" style="margin-left: 20px;">
                        <h5 class="title fw-bolder mb-0 text-capitalize">{{profile.first_name +" "+ profile.last_name}}</h5>
                        <div class="members">
                            <span v-if="this.profile.role == 'seller'" class="fs-5">Ratings: ({{ratings}})</span><br>
                            <strong v-if="this.profile.role == 'seller'">{{ solds }} sold</strong>
                        </div>
                        <div class="members-profile mt-0 justify-content-between" >
                            <div>
                                <!-- <img :src="getImagePath(i)" v-for="i in 10" :key="i" /> -->
                                <!-- <h6>{{profile.contact_number}}</h6>
                                <h6>{{profile.email_address}}</h6> -->
                            </div>
                            <div class="text-end">
                                <button class="border-0 p-2 rounded-2 mx-1" @click="profile_modal = true">
                                    <i class="bx bxs-pencil px-1 fw-bold fs-6"></i>
                                    Edit Profile
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom">
                <hr>
                <div class="bottom-nav justify-content-between pt-2">
                    <div class="discussions d-flex">
                        <div @click="tabs = 'discussions'" :class="tabs == 'discussions' ? 'selected-route' : 'cursor-pointer'">
                            <h6 class="mx-3 pb-1">Post</h6>
                        </div>
                        <div @click="tabs = 'media'" :class="tabs == 'media' ? 'selected-route' : 'cursor-pointer'">
                            <h6 class="mx-3 pb-1">Media</h6>
                        </div>
                    </div>
                    <div class="search-dots pb-3">
                            <!-- <button class="border-0 p-2 rounded-2" >
                                <i class="bx bx-search px-1 fw-bold fs-5" @click="search_modal = true"></i>
                            </button> -->
                            <!-- <b-dropdown
                                right
                                menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
                                toggle-class="header-item noti-icon"
                                variant="black"
                            >
                                <template v-slot:button-content>
                                    <button class="border-0 p-2 rounded-2">
                                        <i class="bx bx-search px-1 fw-bold fs-5" @click="search_modal = true"></i>
                                    </button>
                                </template>
                            </b-dropdown> -->
                            <b-dropdown
                                right
                                menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
                                toggle-class="header-item noti-icon"
                                variant="black"
                            >
                                <template v-slot:button-content>
                                    <button class="border-0 p-2 rounded-2">
                                        <i class="bx bx-dots-horizontal-rounded px-1 fw-bold fs-5"></i>
                                    </button>
                                </template>
                                <!-- item-->
                                <div class="p-2">
                                    <button class="dropdown-item text-dark p-2 fw-bold">
                                        <i
                                            class="bx bx-share font-size-16 align-middle me-1"
                                        ></i>
                                        Share
                                    </button>
                                    <button class="dropdown-item text-dark p-2 fw-bold">
                                        <i
                                            class="bx bx-pin font-size-16 align-middle me-1"
                                        ></i>
                                        Pin posts
                                    </button>
                                </div>
                            </b-dropdown>
                        </div>
                </div>
            </div>
        </div>
        <div class="body" v-if="tabs == 'discussions'">
        <Loader v-if="loading"/>
            <div class="right p-2">
                <div class="about mt-2 border-radius box-shadow">
                    <div class="px-4 pt-4 pb-4">
                        <h6 class="" style="color: #050505">About</h6>
                        <div class="pt-3">
                            <span class="mx-3 text-capitalize" style="margin-top: -2px;"><i class="bx bx-money fs-5 me-2"></i>{{profile.role}} Products</span><br><br>
                            <span class="mx-3 text-capitalize" style="margin-top: -2px;">Birthday: {{profile.birthday}} </span><br><br>
                        </div>
                        <div>
                            <span class="fw-bold">Reviews({{this.new_data.length}})</span>
                            <div class="px-4 pt-2">
                                <p class="bg-light p-2 border-radius-50" v-for="data,i in this.new_data" :key="i"><span class="fw-bold">{{data.n + ": "}}</span>{{data.r}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="about mt-2 border-radius box-shadow">
                    <div class="p-3">
                        <h6 class="title">Recent Media</h6>
                        <span v-for="data,index in this.recent_media" :key="index">
                            <img class="recent-image" v-if="data.length > 0" :src="`${image_api}/${data}`"/>
                        </span>
                    </div>
                    <div class="px-3 pb-3">
                        <button class="see-all p-2">See all</button>
                    </div>
                </div>
            </div>
            <div class="left p-2">
                <div class="post-box p-3 mt-2 border-radius box-shadow" v-if="profile.role == 'seller'"  @click="post_modal = true">
                    <div class="d-flex">
                        <div class="">
                            <img v-if="!this.profile.image" 
                                height="40" width="40"
                                class="raidus-100"
                                src="@/assets/images/profiles/default.png" >
                            <img
                                v-if="this.profile.image"
                                :src="`${image_api}/${this.profile.image}`"
                                height="40" width="40"
                                class="raidus-100"
                            />
                        </div>
                        <div class="post">
                            <button class="post-input">Write something...</button>
                        </div>
                    </div>
                    <hr style="color: #999999;" class="mt-3 mb-2">
                    <div class="col-12 upload">
                        <div class="d-flex" style="justify-content: center;align-items: center; ">
                            <div class="d-flex mx-3">
                                <i class="bx bx-video" style="color: #FC5677;"></i>
                                <h6 class="mt-2 mx-2">Upload/Video</h6>
                            </div>
                            <div class="d-flex mx-3">
                                <i class="bx bx-image" style="color: green;"></i>
                                <h6 class="mt-2 mx-2">Upload/Image</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-2 bg-white border-radius box-shadow">
                    <h6 class="p-3">Featured</h6>
                </div>
                <div class="mt-2 bg-white border-radius box-shadow" v-for="data,i in data.list" :key="i">
                    <div class="d-flex p-3" style="position:relative">
                        <div class="users" >
                            <img v-if="!data.owner_dp" 
                                height="40" width="40"
                                class="raidus-100"
                                src="@/assets/images/profiles/default.png" >
                            <img
                                v-if="data.owner_dp"
                                :src="`${image_api}/${data.owner_dp}`"
                                height="40" width="40"
                                class="raidus-100"
                            />
                            <b-dropdown
                                right
                                menu-class="dropdown-menu-sm p-0 dropdown-menu-end"
                                toggle-class="header-item noti-icon"
                                variant="black"
                                style="position: absolute; right:10px;top:0;" 
                            >
                                <template v-slot:button-content>
                                    <i class="bx bx-dots-horizontal-rounded px-1 fw-bold fs-5"></i>
                                </template>
                                <!-- item-->
                                <div class="p-2">
                                    <button class="dropdown-item text-dark p-2 fw-bold" @click="reportUser(data)">
                                        <i
                                            class="bx bxs-megaphone font-size-16 align-middle me-1"
                                        ></i>
                                        Report
                                    </button>
                                    <button v-if="profile.username == data.owner" class="dropdown-item text-dark p-2 fw-bold" @click="update_post_modal = true">
                                        <i
                                            class="bx bxs-pencil font-size-16 align-middle me-1"
                                        ></i>
                                        Update
                                    </button>
                                    <button v-if="profile.username == data.owner" class="dropdown-item text-danger p-2 fw-bold" @click="deletePost(data)">
                                        <i
                                            class="bx bxs-trash font-size-16 align-middle me-1"
                                        ></i>
                                        Delete
                                    </button>
                                </div>
                            </b-dropdown>
                        </div>
                        <div>
                            <span class="mx-3" style="color: #050505">{{data.owner}}</span>
                            <p class="mx-3" style=" color:#999999; font-size: 10px;">{{`${timeOnly(data.created_at)} ~ ${dateOnly(data.created_at)}`}}</p>
                            <h6 class="mx-3" v-if="data.text != 'null'" v-html="formatText(data.text)"></h6>
                            <!-- <div v-if="data.media">
                                <div class="image-container" v-if="parsedMedia(data.media).length > 0" >
                                    <img @click="postView(image)" v-for="(image, index) in parsedMedia(data.media)" :src="getMediaUrl(image)" :key="index" :alt="`Image ${index + 1}`" class="media-image cursor-pointer" height="250" width="250"/>
                                </div>
                            </div> -->
                            <div v-if="data.media">
                                <div class="image-container" v-if="parsedMedia(data.media).length > 0" >
                                    <div v-for="(image, index) in  parsedMedia(data.media).slice(0,4)" :key="index" class="media-item"> 
                                        <img @click="viewImages(data)" v-if="image.includes('.jpg') || image.includes('.jpeg') || image.includes('.png') ||image.includes('.gif') || image.includes('.tiff') || image.includes('.raw') || image.includes('.heic') || image.includes('.jfif') " :src="getMediaUrl(image)" class="cursor-pointer" height="250" width="250"/>
                                        <video
                                            v-if="image.includes('.mp4')|| image.includes('.webm') || image.includes('.ogg') || image.includes('.mov') || image.includes('.avi') ||image.includes('.wmv') || image.includes('.webm')"
                                            :src="getMediaUrl(image)"
                                            controls
                                            poster='@/assets/images/video.png'
                                            height="250"
                                            width="250"
                                        ></video>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="justify-content-between px-5 pb-2" style="display:flex; align-items:center;">
                        <div class="text-start" v-if="data.reactions">
                            <!-- <span style="color: #999999" v-if="data.reactions.length > 0">
                                {{`${data.reactions.length} ${data.reactions.length > 1 ? 'reactions': 'react'}`}}
                            </span> -->
                            <div class="text-start" v-if="data.reactions_summary">
                                <span style="color: #999999">
                                    {{`${data.reactions_summary.like > 0 ? data.reactions_summary.like + ' 👍 ': ''}`}}
                                </span>
                                <span style="color: #999999">
                                    {{`${data.reactions_summary.haha > 0 ? data.reactions_summary.haha + ' 😆 ': ''}`}}
                                </span>
                                <span style="color: #999999">
                                    {{`${data.reactions_summary.heart > 0 ? data.reactions_summary.heart + ' ❤️ ': ''}`}}
                                </span>
                                <span style="color: #999999">
                                    {{`${data.reactions_summary.wow > 0 ? data.reactions_summary.wow + ' 😮 ': ''}`}}
                                </span>
                            </div>
                        </div>
                        <div class="text-start" v-if="data.comments">
                            <span style="color: #999999" v-if="data.comments.length > 0">
                                {{`${data.comments.length} ${data.comments.length > 1 ? 'comments': 'comment'}`}}
                            </span>
                        </div>
                    </div>
                    <div class="like-comment text-center d-flex px-2">
                        <div>
                            <!-- <i class="bx bx-like p-0 mb-0 fs-5 mx-2"></i><span class="fs-5">Like</span> -->
                            <div class="reaction-button-container" @click="toggleReactions(i,data)">
                                <i class="reaction-button post-buttons bx bx-like p-0 mb-0 fs-5 mx-2"></i><span class="fs-5 post-buttons">Like</span>
                                <div v-if="reactionsVisible == i && toggle" class="reactions">
                                    <div class="reaction" v-for="reaction in reactions" :key="reaction" @click="postReaction(data,$event)">
                                        {{ reaction }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <i class="bx bx-message fs-5 post-buttons mx-2"></i><span class="fs-5 post-buttons">Comment</span>
                        </div>
                        <div  @click="sendTo(data)" :hidden="profile.username == data.owner">
                            <i class="bx bx-send fs-5 post-buttons mx-2"></i><span class="fs-5 post-buttons">Send</span>
                        </div>
                    </div>
                    <div>
                        <div v-if="data.comments.length">
                            <div  class="comment-section d-flex px-3 pt-3"  v-for="(data, index) in data.comments" :key="index">
                                <img v-if="!data.owner_dp" @click="gotoProfile(data)" class="profile-picture" src="@/assets/images/profiles/default.png" >
                                <img
                                    v-if="data.owner_dp"
                                    @click="gotoProfile(data)"
                                    :src="`${image_api}/${data.owner_dp}`"
                                    height="40" width="40"
                                    class="raidus-100"
                                />
                                <div class="comment-details mx-3">
                                
                                    <div class="bubble" style="position: relative;">
                                        <h6 class="fw-bold mb-0">{{data.owner}}</h6>
                                        <span class="text-dark mb-0 comment-text" v-html="formatText(data.text)"></span>
                                        <b-dropdown
                                            right
                                            menu-class="dropdown-menu-sm p-0 dropdown-menu-end"
                                            toggle-class="header-item noti-icon"
                                            variant="black"
                                            style="position: absolute; right:5px;top:-10px;" 
                                        >
                                            <template v-slot:button-content>
                                                <i class="bx bx-dots-horizontal-rounded px-1 fw-bold fs-5"></i>
                                            </template>
                                            <!-- item-->
                                            <div class="p-2">
                                                <button class="dropdown-item text-dark p-2 fw-bold" @click="reportUser(data)">
                                                    <i
                                                        class="bx bxs-megaphone font-size-16 align-middle me-1"
                                                    ></i>
                                                    Report
                                                </button>
                                                <button v-if="profile.username == data.owner" class="dropdown-item text-danger p-2 fw-bold" @click="commentDelete(data)">
                                                    <i
                                                        class="bx bxs-trash font-size-16 align-middle me-1"
                                                    ></i>
                                                    Delete
                                                </button>
                                            </div>
                                        </b-dropdown>
                                    </div>
                                    <div style="color: #999999;font-size: 12px;">
                                        <span class="px-3">{{`${timeOnly(data.created_at)} ~ ${dateOnly(data.created_at)}`}}</span>
                                        <!-- <span class="px-3"><strong>Like</strong></span> -->
                                        <span class="px-3" @click="getIndex(index)" style="cursor:pointer;"><strong>Reply</strong></span>
                                        <div v-if="data.sub_comments.length > 0">
                                            <div  class="comment-section d-flex px-3 pt-3"  v-for="(data, i) in data.sub_comments" :key="i" >
                                                <img v-if="!data.owner_dp" @click="gotoProfile(data)" class="profile-picture" src="@/assets/images/profiles/default.png" >
                                                <img
                                                    v-if="data.owner_dp"
                                                    @click="gotoProfile(data)"
                                                    :src="`${image_api}/${data.owner_dp}`"
                                                    height="40" width="40"
                                                    class="raidus-100"
                                                />
                                                <div class="comment-details mx-3">
                                                    <div class="bubble">
                                                        <h6 class="fw-bold mb-0">{{data.owner}}</h6>
                                                        <span class="text-dark mb-0 comment-text" v-html="formatText(data.text)"></span>
                                                    </div>
                                                    <div style="color: #999999;font-size: 12px;">
                                                        <span class="px-3">{{`${timeOnly(data.created_at)} ~ ${dateOnly(data.created_at)}`}}</span>
                                                        <!-- <span class="px-3"><strong>Like</strong></span> -->
                                                        <span class="px-3" @click="getReplyData(data,index)" style="cursor:pointer;"><strong>Reply</strong></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="comment d-flex p-3" v-if="this.reply_index == index && reply_toggle == true">
                                            <img v-if="!this.profile.image" 
                                                height="32" width="32"
                                                class="raidus-100"
                                                src="@/assets/images/profiles/default.png" >
                                            <img
                                                v-if="this.profile.image"
                                                :src="`${image_api}/${this.profile.image}`"
                                                height="32" width="32"
                                                class="raidus-100"
                                            />
                                            <input :ref="`replyInput${index}`"  placeholder="Comment.." maxlength="500" @keyup.enter="replySubmit($event,data)" :value="this.reply_owner"/>
                                            <i class="bx bx-send send-icon" @click="replyComment(data, index)"></i>
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="comment d-flex p-3">
                        <img v-if="!this.profile.image" 
                            height="32" width="32"
                            class="raidus-100"
                            src="@/assets/images/profiles/default.png" >
                        <img
                            v-if="this.profile.image"
                            :src="`${image_api}/${this.profile.image}`"
                            height="32" width="32"
                            class="raidus-100"
                        />
                        <input :ref="`commentInput${i}`" placeholder="Comment.." maxlength="500" @keyup.enter="commentSubmit($event,data,i)"/>
                        <i class="bx bx-send send-icon" @click="submitComment(data, i)"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="body" v-if="tabs == 'media'">
            <div class="w-100">
                <div class="row mt-4">
                    <div
                        v-for="data in this.recent_media"
                        :key="data.id"
                        class="col-xl-4 col-sm-6"
                    >
                        <div  class="box-shadow">
                            <img class="see-all-media card p-2" v-if="data.length > 0" :src="`${image_api}/${data}`"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- MODALS -->
        <b-modal 
            v-model="post_modal" 
            no-close-on-backdrop
            hide-footer
            size="md"
            title="Create Post" 
            centered
            >
            <div class="d-flex">
                <div class="users">
                    <div class="">
                        <img v-if="!this.profile.image" 
                            height="40" width="40"
                            class="raidus-100"
                            src="@/assets/images/profiles/default.png" >
                        <img
                            v-if="this.profile.image"
                            :src="`${image_api}/${this.profile.image}`"
                            height="40" width="40"
                            class="raidus-100"
                        />
                    </div>
                </div>
                <div class="mx-3">
                    <span style="color: #050505">Test Name</span>
                    <p style=" color:#999999; font-size: 14px;">Public</p>
                </div>
            </div>
            <textarea class="form-control mb-3 write-something-input" placeholder="Write something..." v-model="pl_post.text">
            </textarea>
            <input
                type="file"
                ref="uploadFile"
                multiple 
                class="form-control mb-3" 
                @change="handleFileSelect" accept="image/*,video/*"
                />
            <div class="text-end mt-3">
                <b-button class="mx-1 w-100" variant="info" @click="addPost()">Post</b-button>
            </div>
        </b-modal>
        <b-modal 
            v-model="search_modal" 
            no-close-on-backdrop
            hide-footer
            size="md"
            title="Search" 
            centered
            >
            <div class="d-flex mb-3">
                <div class="search w-100">
                    <i class="bx bx-search"></i>
                    <input
                        type="text"
                        placeholder="Search this group"
                        class="form-control"
                    />
                </div>
            </div>
            <h5>Recent Searches</h5>
            <div>
                <div class="d-flex p-3 recent-searches-button" v-for="data,i in 5" :key="i">
                    <i class="bx bx-time-five fs-4"></i>
                    <span class="mx-2">Bags</span>
                </div>
            </div>
        </b-modal>
        <b-modal 
            v-model="share_modal" 
            no-close-on-backdrop
            hide-footer
            size="md"
            title="Share" 
            centered
            >
            <h5>Copy Link</h5>
            <input class="form-control mb-3" value="kio88.com/id=Dq12eyv4asdq6772"/>
        </b-modal>
        <b-modal 
            v-model="invite_modal" 
            no-close-on-backdrop
            hide-footer
            size="md"
            title="Invite" 
            centered
            >
            <h5>Link</h5>
            <input class="form-control mb-3" disabled/>
        </b-modal>
        <b-modal 
            v-model="image_modal" 
            no-close-on-backdrop
            hide-footer
            size="lg"
            title="Image" 
            centered
            >
            <div v-if="parsedMedia(this.get_ads_data.media).length > 0">
                <img height="500" style="width: 100%;" v-for="(image, index) in parsedMedia(this.get_ads_data.media)" :src="getMediaUrl(image)" :key="index" :alt="`Image ${index + 1}`"/>
            </div>
            <div v-else>
                <img height="500" style="width: 100%;" src="@/assets/images/no-image.png">
            </div>
            
        </b-modal>
        <b-modal 
            v-model="all_ads_modal" 
            no-close-on-backdrop
            hide-footer
            size="lg"
            title="Advertisements" 
            centered
            >
            <div id="carouselExampleCaption" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner" role="listbox">
                    <div :class="index == 0 ? 'carousel-item active' : 'carousel-item'" v-for="data,index in this.data_announcement.list" :key="index">
                        <div v-if="parsedMedia(data.media).length > 0">
                            <img height="500" style="width: 100%;" v-for="(image, index) in parsedMedia(data.media)" :src="getMediaUrl(image)" :key="index" :alt="`Image ${index + 1}`"/>
                        </div>
                        <div v-else>
                            <img height="500" style="width: 100%;" src="@/assets/images/no-image.png">
                        </div>
                        <div class="carousel-caption d-none d-md-block" style="background-color: #000000b4; border-radius: 15px;">
                            <h5 class="text-white">{{data.name }}</h5>
                            <p class="text-white">{{data.description}}</p>
                        </div>
                    </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleCaption" role="button"
                    data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleCaption" role="button"
                    data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </b-modal>
        <b-modal 
            v-model="profile_modal" 
            no-close-on-backdrop
            hide-footer
            size="lg"
            title="Change Picture" 
            centered
            >
            <div class="row">
                <div class="col-6">
                    <label>Profile Picture</label>
                    <div class="text-center mb-3">
                        <img :src="profile_picture ? profile_picture: require('@/assets/images/no-image.png')" style="width: 100%; height: 300px;"/>
                    </div>
                    <input type="file" class="form-control mb-3" @change="profileHandle">
                </div>
                <div class="col-6">
                    <label>Cover Photo</label>
                    <div class="text-center mb-3">
                        <img :src="cover_photo ? cover_photo: require('@/assets/images/no-image.png')" style="width: 100%; height: 300px;"/>
                    </div>
                    <input type="file" class="form-control mb-3" @change="coverHandle">
                </div>
            </div>
            <button class="btn btn-info w-100" @click="updateProfile()">Save</button>
        </b-modal>
        <b-modal 
            v-model="see_all_recent_media" 
            no-close-on-backdrop
            hide-footer
            size="lg"
            title="Gallery" 
            centered
            >
            <div class="row recent-media">
                <div
                    v-for="data in this.recent_media"
                    :key="data.id"
                    class="col-xl-4 col-sm-6"
                >
                    <div  class="card p-1 box-shadow">
                        <img class="see-all-media card p-2" v-if="data.length > 0" :src="`${image_api}/${data}`"/>
                    </div>
                </div>
            </div>
        </b-modal>
        <b-modal 
            v-model="view_all_modal" 
            no-close-on-backdrop
            hide-footer
            size="lg"
            title="View All" 
            centered
            >
            <div
                v-for="data,index in this.view_all_media"
                :key="index"
            >
                <div  class="box-shadow view-images">
                    <img class="p-1" v-if="data.length > 0" :src="`${image_api}/${data}`" />
                </div>
            </div>
        </b-modal>
        <b-modal 
            v-model="post_view_modal" 
            hide-footer
            size="md"
            title="View" 
            centered
            hide-header
            >
            <img class="post-view-image image-pointer" :src="`${image_api}/${this.post_view_media}`"/>
        </b-modal>
    </div>
</template>
<script>
import navBar from "@/components/nav-bar.vue";
import '@/assets/css/user-dashboard.css';
import { mapActions,mapGetters } from "vuex";
import formatter from '@/mixins/formatter';
import Swal from 'sweetalert2';
import Loader from "@/components/widgets/loader";
import Echo from "laravel-echo";
export default {
    data() {
        return {
            loading: false,
            ratings_count: 0,
			data: {
				list: [
                    {
                        comments:[],
                    }
                ],
                reactions:[],
                media:[],
				links: [],
				current_page: 1,
				per_page: 1,
				last_page: 1,
			},
            profile_data:{},
			data_announcement: {
				list: [],
                media:[],
				links: [],
				current_page: 1,
				per_page: 1,
				last_page: 1,
			},
            pl_post:{
                text: null,
                reaction: null,
            },
            content: '',
            post_modal: false,
            search_modal: false,
            share_modal: false,
            invite_modal: false,
            image_modal: false,
            all_ads_modal: false,
            see_all_recent_media: false,
            post_view_modal: false,
            profile_modal:false,
            slide: 0,
            sliding: null,
            image_api: process.env.VUE_APP_BACKEND_API_IMAGES,
            mediaInput: null,
            selectedMedia: [],
            mediaJSON: null,
            comment: null,
            reply_index: null,
            reply_toggle:false,
            reply_owner: "",
            recent_media:[],
            post_id:null,
            comment_id:null,
            get_ads_data: [],
            post_view_media: [],
            reactionsVisible: false,
            reaction_id: null,
            toggle: false,
            selected_profile_picture: null,
            profile_picture: null,
            cover_photo: null,
            selected_cover_photo: null,
            reactions: ['👍','😆','❤️', '😮'],
            new_data: [],
            view_all_media: [],
            view_all_modal: false,
            reviews:[
            "Great product, increased our sales by 20%!",
            "Sales have been steady since we started using this.",
            "Helped boost our monthly sales figures.",
            "Saw a significant rise in sales after implementing.",
            "Sales doubled within the first quarter!",
            "Consistent sales growth every month.",
            "Helped us reach our sales targets faster.",
            "Sales improved drastically.",
            "Our sales team loves it, sales are up.",
            "Increased our conversion rates and sales.",
            "Sales numbers have never been better.",
            "Positive impact on our overall sales performance.",
            "Boosted our sales pipeline.",
            "Sales have increased significantly.",
            "Excellent tool for increasing sales.",
            "Noticeable improvement in our sales metrics.",
            "Sales figures are higher than ever.",
            "Has greatly enhanced our sales strategy.",
            "Sales are up and continue to grow.",
            "Major boost in our quarterly sales.",
            "Reliable product for improving sales.",
            "Helped us exceed our sales goals.",
            "Outstanding results in sales growth.",
            "Very effective in driving sales.",
            "Strong impact on our sales results.",
            "Sales growth has been phenomenal.",
            "Impressive boost in sales performance.",
            "A must-have for sales improvement.",
            "Substantial increase in our sales.",
            "Remarkable rise in our sales numbers.",
            "Sales have skyrocketed since we started using this.",
            "Helped us capture new markets and boost sales.",
            "Our sales have tripled in just a few months.",
            "Great investment for our sales team.",
            "Sales trends are going upwards.",
            "Enhanced our sales processes significantly.",
            "Sales performance has improved across the board.",
            "Boosted our sales with minimal effort.",
            "Helped us gain a competitive edge in sales.",
            "Incredible results in sales figures.",
            "Sales have been consistently high.",
            "Significant contribution to our sales growth.",
            "Highly recommend for improving sales.",
            "Our best sales month yet!",
            "Sales have been on an upward trajectory.",
            "Great tool for sales optimization.",
            "Helped us achieve record-breaking sales.",
            "Outstanding increase in our sales revenue.",
            "Sales have been exceptional.",
            "We are seeing higher sales numbers than ever before.",
            "Enhanced our sales productivity.",
            "Greatly improved our sales conversion rates.",
            "Helped us capture new sales opportunities.",
            "Sales have been strong and consistent.",
            "Excellent for boosting sales efficiency.",
            "Helped us surpass our sales forecasts.",
            "Sales have been remarkable.",
            "Noticeable uptick in our sales volume.",
            "Our sales team is thrilled with the results.",
            "Achieved our highest sales quarter.",
            "Sales figures have been very impressive.",
            "Helped us streamline our sales efforts.",
            "Increased our sales pipeline considerably.",
            "Very effective tool for driving sales.",
            "Sales have been nothing short of amazing.",
            "Our sales have grown exponentially.",
            "Helped us unlock new sales potential.",
            "Increased our sales performance dramatically.",
            "Excellent impact on our sales growth.",
            "Our sales targets were easily met.",
            "Greatly boosted our sales capabilities.",
            "Sales are at an all-time high.",
            "Helped us reach new sales milestones.",
            "Our sales productivity has soared.",
            "Sales have exceeded our expectations.",
            "Very beneficial for our sales strategy.",
            "Outstanding tool for driving sales growth.",
            "Helped us secure more sales leads.",
            "Sales have been consistently improving.",
            "Noticeable increase in our sales conversion rates.",
            "Boosted our overall sales performance.",
            "Sales growth has been steady and strong.",
            "Very effective in improving sales metrics.",
            "Sales have been impressive and steady.",
            "Great for optimizing our sales process.",
            "Sales targets have been easily met.",
            "Helped us achieve significant sales milestones.",
            "Our sales team is seeing fantastic results.",
            "Substantial improvement in our sales figures.",
            "Sales have been incredibly strong.",
            "Achieved new highs in our sales performance.",
            "Sales growth has been outstanding.",
            "Helped us drive more sales leads.",
            "Increased our sales efficiency greatly.",
            "Remarkable impact on our sales strategy.",
            "Sales figures have consistently improved.",
            "Helped us boost our sales metrics.",
            "Sales have been better than ever.",
            "Impressive improvement in our sales conversion rates.",
            "Helped us maintain high sales growth."
            ],
			first_name:[
				"Lucina",
				"Michelle",
				"Lemmy",
				"Monro",
				"Olivier",
				"Dagny",
				"Muffin",
				"Gisele",
				"Pall",
				"Patsy",
				"Berenice",
				"Rorie",
				"Shayne",
				"Benny",
				"Garrik",
				"Carce",
				"Analise",
				"Caye",
				"Paul",
				"Zsa",
				"Rana",
				"Gerard",
				"Jodi",
				"Rodrigo",
				"Averell",
				"Jaclin",
				"Sinclair",
				"Jarret",
				"Cris",
				"Elisha",
				"Mil",
				"Stevena",
				"Bettye",
				"Silvano",
				"Dorotea",
				"Tamiko",
				"Caesar",
				"Tedd",
				"Dalton",
				"Marsh",
				"Gearard",
				"Philly",
				"Mildred",
				"Audrie",
				"Rudolph",
				"Even",
				"Ailene",
				"Marian",
				"Sutton",
				"Harv",
				"Rowland",
				"Kienan",
				"Beauregard",
				"Modesty",
				"Glenda",
				"Adlai",
				"Evelyn",
				"Marie",
				"Mortie",
				"Lorna",
				"Fleming",
			],
            tabs: 'discussions',
            max_size_post: false,
        };
    },
    mixins:[formatter],
	components: {
		navBar,Loader
	},
	computed: {
        ...mapGetters('auth', ['profile']),
        solds(){
            var solds = Math.floor(Math.random() * 300)
            return solds
        },
        ratings(){
            var ratings = Math.floor(Math.random() * 2) + 1;
            var storage;
            if(ratings == 1){
                storage = '⭐⭐⭐⭐';
            }else if(ratings == 2){
                storage = '⭐⭐⭐⭐⭐';
            }
            return storage
        },
	},
    methods:{
		...mapActions("transaction", {
			iList: "getList",
		}),
		...mapActions("auth", {
			getProfile: "getUsersProfile",
		}),
		...mapActions("posts", {
			createPost: "createPosts",
			getList: "getPosts",
			reactionPost: "postReaction",
			removePost: "postRemove",
		}),
		...mapActions("comment", {
			addComment: "createComment",
			getComment: "getComments",
			addReplies: "addReply",
			deleteComment: "removeComment",
		}),
		...mapActions("ban", {
			userBan: "banUser",
		}),
		...mapActions("notifications", {
			getNotifications: "notificationList",
		}),
		...mapActions("profile", {
			profileUpdate: "update",
		}),
		...mapActions("messages", {
			getThreads: "getList",
			storeThread: "newThread",
			checkerThread: "checkThread",
			messageUser: "message",
			showUserThread: "showThread",
		}),
        scrollToTop() {
            // window.scrollTo({ top: 0, behavior: 'smooth' });
            window.scrollTo(0,0);
        },
        async initList(p) {
			var pl = {
				page: p,
			};
            if(this.$route.query.user_id){
                pl['user_id'] = this.$route.query.user_id;
            }else{
                pl['user_id'] = this.profile.id;
            }
			const data = await this.getList(pl);
			this.data.list = data.data;
			this.data.list.comments = data.data.comments;
			this.data.reactions = data.data.reactions;
			this.data.links = data.links;
			this.data.current_page = data.current_page;
			this.data.per_page = data.per_page;
			this.data.last_page = data.last_page;
            const arr = data.data;
            var storage = [];
            arr.forEach(element => {
                if(element.media){
                    storage.push(element.media)
                }
            });
            let parsedData = Object.values(storage).map(item => JSON.parse(item));
            this.recent_media = parsedData;
			this.webSocket();
		},
        async initUserProfile() {
			var pl = {
                user_id: this.$route.query.user_id
			};
            this.loading = true;
			const data = await this.getProfile(pl);
            this.loading = false;
			this.profile_data = data.data;
		},
        onSlideStart() {
            this.sliding = true
        },
        onSlideEnd() {
            this.sliding = false
        },
        getAdsData(data){
            this.get_ads_data = data;
        },
        formatText(text) {
            if(text){
                return text.replace(/\r/g, '<br>');
            }
        },
        // RESPONSE MEDIA
        parsedMedia(media) {
            try {
                return JSON.parse(media);
            } catch (error) {
                return [];
            }
        },
        getMediaUrl(filename) {
            return `${this.image_api}/${filename}`;
        },
        // PROFILE IMAGE
        getImagePath(index) {
            return require(`@/assets/images/profiles/${index}.jpg`);
        },
        handleFileSelect(event) {
            var media_storage = 0;
            this.selectedMedia = Array.from(event.target.files);
            this.selectedMedia.forEach(element => {
                media_storage += element.size
                if(media_storage > 50000000){
                    this.max_size_post = true;
                }
            });
        },
        async addPost(){
            const fd = new FormData();
            this.selectedMedia.forEach((file, index) => {
                fd.append(`media[${index}]`, file);
            });
            fd.append('text', this.pl_post.text);
            //  const media = this.selectedMedia.map((file, index) => ({
            //     [`media[${index}]`]: file
            // }));
            // var pl ={
            //     media: media,
            //     text: this.pl_post.text
            // }
            if(this.max_size_post == true){
                this.loading = false;
                this.post_modal = false;
                this.max_size_post = false;
                Swal.fire({
                    html: `You've reached the max file size limit (100mb)`,
                    type: "error",
                    icon: "error",
                    padding: "2em",
                });
            }else{
                this.loading = true;
                this.post_modal = false;
                const res = await this.createPost(fd);
                if (res.status == 200|| res.status == 'success') {
                    // Swal.fire({
                    //     title: "Success",
                    //     html: `Posted.`,
                    //     type: "success",
                    //     icon: "success",
                    //     padding: "2em",
                    // });
                    this.initList(1);
                    this.post_modal = false;
                    this.loading = false;
                }else{
                    this.initList(1);
                    Swal.fire({
                        html: res,
                        type: "error",
                        icon: "error",
                        padding: "2em",
                    });
                }
            }
        },
        async deletePost(data){
            var pl = {
                id: data._id,
            }
            const confirmed = await Swal.fire({
                title: 'Are you sure you want to DELETE this?',
                html: `<table class="table table-bordered table-sm font-size-12 text-start">
                            <tbody>
                                <tr>
                                    <th>Description</th>
                                    <td>${data.text}</td>
                                </tr>
                            </tbody>
                        </table>`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: '#74788d',
            }).then((result) => {
                return result.value;
            });
            if (confirmed) {
                const res = await this.removePost(pl);
                if (res.status == 200|| res.status == 'success') {
                    Swal.fire({
                        title: "Success",
                        html: `Deletion Successful.`,
                        type: "success",
                        icon: "success",
                        padding: "2em",
                    });
                    this.initList(1);
                }else{
                    Swal.fire({
                        html: res,
                        type: "error",
                        icon: "error",
                        padding: "2em",
                    });
                }
            }
        },
        async commentDelete(data){
            var pl = {
                id: data._id,
            }
            const confirmed = await Swal.fire({
                title: 'Are you sure you want to DELETE this?',
                html: `<table class="table table-bordered table-sm font-size-12 text-start">
                            <tbody>
                                <tr>
                                    <th>Description</th>
                                    <td>${data.text}</td>
                                </tr>
                            </tbody>
                        </table>`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: '#74788d',
            }).then((result) => {
                return result.value;
            });
            if (confirmed) {
                const res = await this.deleteComment(pl);
                if (res.status == 200|| res.status == 'success') {
                    Swal.fire({
                        title: "Success",
                        html: `Deletion Successful.`,
                        type: "success",
                        icon: "success",
                        padding: "2em",
                    });
                    this.initList(1);
                }else{
                    Swal.fire({
                        html: res,
                        type: "error",
                        icon: "error",
                        padding: "2em",
                    });
                }
            }
        },
        async postReaction(data,event){
            var pl = {
                post_id: data._id,
            }
            if(event.target.innerHTML == '👍'){
                pl['type'] = 'like'
            }
            if(event.target.innerHTML == '😆'){
                pl['type'] = 'haha'
            }
            if(event.target.innerHTML == '❤️'){
                pl['type'] = 'heart'
            }
            if(event.target.innerHTML == '😮'){
                pl['type'] = 'wow'
            }
           await this.reactionPost(pl);
        },
        async commentSubmit(event,data){
            const fd = new FormData();
            fd.append('text', event.target.value);
            fd.append('post_id', data._id);
            this.post_id =data._id;
            const success = await this.addComment(fd);
            if(success.status == 200 || success.status == 'success'){
                this.initList(1);
            }
            event.target.value = ''
        },
        submitComment(data, i) {
            const commentInput = this.$refs['commentInput' + i][0];
            const event = { target: commentInput };
            this.commentSubmit(event, data, i);
        },
        async replySubmit(event,data){
            const fd = new FormData();
            fd.append('text', event.target.value);
            fd.append('comment_id', data._id);
            this.comment_id = data._id
            const success = await this.addReplies(fd);
            if(success.status == 200 || success.status == 'success'){
                this.initList(1);
            }
            event.target.value = ''
        },
        replyComment(data, index) {
            const replyInput = this.$refs['replyInput' + index][0];
            const event = { target: replyInput };
            this.replySubmit(event, data, index);
        },
        getIndex(index){
            this.reply_index = index;
            if(this.reply_toggle == false){
                this.reply_toggle = true;
            }else{
                this.reply_toggle = false;
            }
        },
        getReplyData(data,index){
            this.reply_owner = `${data.owner}: `
            this.reply_index = index;
            if(this.reply_toggle == false){
                this.reply_toggle = true;
            }else{
                this.reply_toggle = false;
                this.reply_owner = ""
            }
        },
        async messageUser(){
            var pl = {
                receiver_id : this.$route.query.user_id
            }
            const success = await this.checkerThread(pl)
            if(success.status == 200 || success.status == 'success'){
                this.$router.push({
                    path:"/messages",
                    query: {
                        id: this.$route.query.user_id,
                        name: this.$route.query.name,
                    },
                })
            }else{
                const fd = new FormData();
                fd.append('receiver_id', this.$route.query.user_id);
                await this.storeThread(fd);
                this.$router.push({
                    path:"/messages",
                    query: {
                        id: this.$route.query.user_id,
                        name: this.$route.query.name,
                    },
                })
            }
        },
        async sendTo(data){
            // const fd = new FormData();
            // fd.append('receiver_id', data.owner_id);
            // await this.storeThread(fd);
            // this.$router.push({
            //     path:"/messages",
            //     query: {
			// 		id: data.owner_id,
			// 		name: data.owner,
			// 	},
            // })
            var pl = {
                receiver_id : data.owner_id
            }
            const success = await this.checkerThread(pl)
            if(success.status == 200 || success.status == 'success'){
                this.$router.push({
                    path:"/messages",
                    query: {
                        id: data.owner_id,
                        name: data.owner,
                    },
                })
            }else{
                const fd = new FormData();
                fd.append('receiver_id', data.owner_id);
                await this.storeThread(fd);
                this.$router.push({
                    path:"/messages",
                    query: {
                        id: data.owner_id,
                        name: data.owner,
                    },
                })
            }
        },
        async reportUser(data){
            var pl = {
                id: data.owner_id
            }
            const res = await this.userBan(pl);
            if (res.status == 200|| res.status == 'success') {
                Swal.fire({
                    title: "Success",
                    html: `You successfully reported this user.`,
                    type: "success",
                    icon: "success",
                    padding: "2em",
                });
                this.initList(1);
            }else{
                Swal.fire({
                    html: res,
                    type: "error",
                    icon: "error",
                    padding: "2em",
                });
            }
        },
        toggleReactions(i,data) {
            this.reactionsVisible = i;
            this.reaction_id = data._id;
            if(this.toggle == false){
                this.toggle = true
            }else{
                this.toggle = false;
            }
        },
        postView(data){
            this.post_view_modal = true;
            this.post_view_media = data;
        },
		profileHandle(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = e => {
                this.profile_picture = e.target.result;
                };
                reader.readAsDataURL(file);
                this.selected_profile_picture = file;
            }
        },
		coverHandle(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = e => {
                this.cover_photo = e.target.result;
                };
                reader.readAsDataURL(file);
                this.selected_cover_photo = file;
            }
        },
        async updateProfile(){
            const fd = new FormData();
            if(this.selected_cover_photo){
                fd.append('cover', this.selected_cover_photo);
            }
            if(this.selected_profile_picture){
                fd.append('image', this.selected_profile_picture);
            }
            this.loading = true;
            this.profile_modal = false;
            const res = await this.profileUpdate(fd);
            this.loading = false;
            if (res.status == 200|| res.status == 'success') {
                Swal.fire({
                    title: "Success",
                    html: `Profile updated.`,
                    type: "success",
                    icon: "success",
                    padding: "2em",
                });
                setTimeout(() => {
                    this.initList(1)
                }, 2000);
                this.profile_modal = false;
            }else{
                Swal.fire({
                    html: res,
                    type: "error",
                    icon: "error",
                    padding: "2em",
                });
            }
        },
		randomizer(myReviews,fname){
			for (let index = 0; index < 3; index++) {
				myReviews = this.reviews[Math.floor(Math.random() * this.reviews.length)]
				fname = this.first_name[Math.floor(Math.random() * this.first_name.length)]
				var pl= {
					r: myReviews,
                    n: fname
				}
				this.new_data.push(pl)
			}
		},
        viewImages(data){
            this.view_all_modal = true;
            this.view_all_media =  JSON.parse(data.media);
        },
        webSocket() {
			window.Pusher = require("pusher-js");
			window.Echo = new Echo({
				broadcaster: "pusher",
				key: process.env.VUE_APP_PUSHER_APP_KEY,
				// wsHost: process.env.VUE_APP_PUSHER_HOST,
				wsPort: process.env.VUE_APP_PUSHER_PORT,
				disableStats: true,
				cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
				enabledTransports: ["ws", "wss"],
			});
			window.Echo.channel(`new-notification`).listen(
				"NewNotificationEvent",
				async (e) => {
                    const cindex = this.data.list.findIndex(i => i._id == this.post_id);
                    // const sindex = this.data.list.findIndex(i => i._id == this.comment_id);
                    const react_index = this.data.list.findIndex(i => i._id == this.reaction_id);
                    if(e.type == 'like' || e.type == 'haha' || e.type == 'heart' || e.type == 'wow'){
                        var pl_like = {
                            reactions: e.reactions,
                            text: e.text,
                            media: e.media,
                            owner_id: e.owner_id,
                            owner: e.owner,
                            created_at: e.created_at,
                            comments: []
                        };
                        this.post_modal = false;
                        await this.data.list[react_index].reactions.push(pl_like);
                    }
                    if(e.type == 'post'){
                        var pl = {
                            reactions: e.reactions,
                            text: e.text,
                            media: e.media,
                            owner_id: e.owner_id,
                            owner: e.owner,
                            created_at: e.created_at,
                            comments: []
                        };
                        this.post_modal = false;
                        await this.data.list.unshift(pl);
                    }
                    if(e.type == 'comment'){
                        var pl_comment = {
                            text: e.text,
                            media: e.media,
                            owner_id: e.owner_id,
                            owner: e.owner,
                            created_at: e.created_at,
                            owner_dp: e.owner_dp,
                            sub_comments: []
                        };
                        this.data.list.filter(element => {
                            if(element._id == this.post_id){
                                this.data.list[cindex].comments.push(pl_comment);
                            }
                        });
                    }
                    if(e.type == 'subComment'){
                        // var pl_subcomment = {
                        //     text: e.text,
                        //     media: e.media,
                        //     owner_id: e.owner_id,
                        //     owner: e.owner,
                        //     created_at: e.created_at,
                        //     owner_dp: e.owner_dp,
                        // };
                        // this.data.list[cindex].filter(element => {
                        //     if(element._comment_id == this.comment_id){
                        //         this.data.list[cindex].comments[sindex].push(pl_subcomment);
                        //     }
                        // });
                    }
				}
			);
		}
    },
    mounted(){
        setTimeout(() => {
            this.scrollToTop();
            this.initList(1);
        }, 1500);
        this.randomizer();
        if(this.$route.query.user_id){
            this.initList(1);
            this.initUserProfile();
        }
    }
};
</script>
<style scoped>
.reaction-button-container {
  position: relative;
  display: inline-block;
}

.reactions {
  display: flex;
  position: absolute;
  top: -70px;
  left: -30px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease;
}
.reaction {
  font-size: 24px;
  padding: 5px;
  margin: 0 2px;
  cursor: pointer;
  transition: transform 0.2s;
}

.reaction:hover {
  transform: scale(1.5);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>